<template>
  <section>
    <div class="grid grid-cols-2 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">Id IPS</span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.IdIps" />
        <MessageError :text="errors.IdIps"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Nombre IPS</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.NomIps" />
        <MessageError :text="errors.NomIps"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import IpsService from '../../../../../services/ips.service'

// instancias
const _IpsService = ref(new IpsService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idIps: String
})
// referencias

const validationSchema = yup.object({
  IdIps: yup.string().required('El id es requerido').label(''),
  NomIps: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('IdIps', null, { initialValue: '' })
  useField('NomIps', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idIps) {
      _IpsService.value.editarIps(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Ips Actualizado',
          text: 'La Ips se ha actualizado correctamente'
        }).then(() => {
          emit('crearIps')
        })
      })
    } else {
      _IpsService.value.crearIps(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Ips creada',
          text: 'La Ips se ha creado correctamente'
        }).then(() => {
          emit('crearIps')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    IdIps: '',
    NomIps: ''
  }
}

onMounted(() => {
  if (props.idIps) {
    _IpsService.value.getIps(props.idIps).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
